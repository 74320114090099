import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Loader } from 'ui/Components/Loader';
import { useHistory } from 'react-router-dom';

const AuthContext = createContext({ user: null });
export const useAuth = () => useContext(AuthContext);

const setAxiosDefaults = () => {
  axios.defaults.headers.common['Authorization'] =
    window.localStorage.getItem('token');
};

export const AuthProvider = (props) => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  // useEffect(() => {
  //   if (!user || user.role === 'guest') return;
  //   setAxiosDefaults();
  // }, [user]);

  useEffect(() => {
    if (!window.localStorage.getItem('token')) {
      return setLoading(false);
    }
    setAxiosDefaults();
    auth();
  }, []);

  const auth = async () => {
    try {
      const { data } = await axios.get('/auth');
      setUser(data);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (e) {
      console.error(e);
      if (e.response.status === 401) {
        logout();
        window.location.reload();
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const logout = () => {
    window.localStorage.removeItem('token');
    setUser(null);
  };

  const login = async (loginData) => {
    try {
      let { data } = await axios.post('/login', loginData);
      try {
        window.localStorage.setItem('token', data.token);
        setAxiosDefaults();
      } catch (error) {
        console.error('localStorage error', error);
      }
      setUser(data.user);
      history.push('/');
      return { value: '', status: '' };
      // return data;
    } catch (e) {
      console.error(e.response.data);
      return { value: 'E-Mail oder Passwort sind Falsch!', status: 'error' };
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <AuthContext.Provider
        value={{ user, loading, logout, login, setUser }}
        {...props}
      />
    </>
  );
};
